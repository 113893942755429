@tailwind base;
@tailwind components;
@tailwind utilities;

.menu {
  @apply flex items-center p-2 rounded-lg text-slate-50;
}

.career-section {
  @apply flex flex-col gap-3 border-b py-[35px] last:border-b-0 border-horizontalRow;
}

.section-title {
  @apply font-[400] text-[20px] leading-6 font-sans;
}

body {
  -webkit-font-smoothing: antialiased;
}

video {
  width: unset !important;
}

.react-player__preview,
video {
  border-radius: 10px;
}

.react-player__shadow {
  background: #eef9fb !important;
}
.react-player__play-icon {
  border-style: solid;
  border-width: 16px 0px 16px 26px;
  border-color: transparent transparent transparent #60849f !important;
  margin-left: 7px;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
/* .no-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.no-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.no-scrollbar::-webkit-scrollbar-thumb {
  background-color: #cccacaf2;
} */
